<template>
  <div>
    <el-container>
      <!-- <CommonTree
              ref="commonTreeCore"
              treeTitle="组织架构"
              :searchTitle="searchTitle"
              :treeData="treeData"
              :defaultProps="defaultProps"
              @getNodeClick="handleNodeClick"
              :isShowdig="false"
              :showCheckbox="false"
              lazy
              :load="treeLoad"
              node-key="id"
          /> -->
      <tree-layout
      v-if="!isUserPage"
        treeTitle="组织架构"
        ref="treeLayout"
        :tree-options="treeOptions"
        :default-expanded-keys="defaultExpandedKeys"
        @tree-node-click="handleNodeClick"
        style="margin-right: 5px"
        highlight-current
        :treeLoading="false"
      ></tree-layout>

      <el-main>
        <head-layout
          :head-title="!isUserPage?'健康档案':''"
          :head-btn-options="headBtnOptions"
          @head-save="handleHeadSave"
          @head-donwnload="handleHeadDownload"
        />
        <div style="padding: 0 12px" v-loading="loading">
          <el-container>
            <!-- <el-aside style="width: 200px">
              <el-image
                :src="form.avatar"
                style="width: 178px; height: 178px; padding: 10px"
              ></el-image>
            </el-aside> -->
            <el-main>
              <div class="form-box">
                <el-form
                :model="form"
                ref="form"
                label-width="85px"
                :rules="rules"
              >
                <el-row>
                  <el-col :span="8" v-if="!isUserPage">
                    <el-form-item label="姓名:" prop="userName">{{
                      form.userName
                    }}</el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="!isUserPage">
                    <el-form-item label="性别:" prop="sexName">{{
                      form.sexName
                    }}</el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="!isUserPage">
                    <el-form-item label="出生年月:" prop="birthday">{{
                      form.birthday
                    }}</el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="!isUserPage">
                    <el-form-item label="所在部门:" prop="deptName">{{
                      form.deptName
                    }}</el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="!isUserPage">
                    <el-form-item label="岗位:" prop="postName">{{
                      form.postName
                    }}</el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="入职日期:" prop="entryTime" v-if="!isUserPage">{{
                      form.entryTime
                    }}</el-form-item>
                  </el-col>
                  <el-col :span="8" v-if="!isUserPage">
                    <el-form-item label="血型:" prop="bloodType">
                      <el-radio-group v-model="form.bloodType">
                        <el-radio
                          v-for="item in bloodTypeList"
                          :key="item.dictKey"
                          :label="item.dictKey"
                          >{{ item.dictValue }}</el-radio
                        >
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="建档日期:" prop="documentDate">
                      <el-date-picker
                        v-model="form.documentDate"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="职业禁忌:" prop="contraindication">
                      <el-input
                        type="textarea"
                        v-model="form.contraindication"
                        maxlength="255"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="接害因素:" prop="hazard">
                      <el-input
                        type="textarea"
                        v-model="form.hazard"
                        maxlength="255"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
              </div>
            </el-main>
          </el-container>
          <template v-if="form.id">
            <el-tabs
              type="border-card"
              v-model="activeName"
              @tab-click="handleClick"
            >
              <el-tab-pane label="保险投保记录" name="insurance">
                
              </el-tab-pane>
              <el-tab-pane v-if="!isUserPage" label="危害接触史" name="hazard">
                <hazard
                  ref="hazard"
                  :active="activeName"
                  :documentInfo="form"
                  v-if="activeName == 'hazard'"
                ></hazard>
              </el-tab-pane>
              <el-tab-pane v-if="!isUserPage" label="体检记录" name="testRecords">
                <testRecords
                  ref="testRecords"
                  :active="activeName"
                  :documentInfo="form"
                  v-if="activeName == 'testRecords'"
                ></testRecords>
              </el-tab-pane>
              <el-tab-pane v-if="!isUserPage" label="劳保用品领用记录" name="requisition">
                <requisition
                  ref="requisition"
                  :active="activeName"
                  :documentInfo="form"
                  v-if="activeName == 'requisition'"
                ></requisition>
              </el-tab-pane>
              <!-- <el-tab-pane label="职业病病历" name="medicalRecord">
                <medicalRecord
                  ref="medicalRecord"
                  :active="activeName"
                  :documentInfo="form"
                  v-if="activeName == 'medicalRecord'"
                ></medicalRecord>
              </el-tab-pane> -->
            </el-tabs>
            <insurance
                  ref="insurance"
                  :active="activeName"
                  :documentInfo="form"
                  v-if="activeName == 'insurance'"
                ></insurance>
          </template>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree/index";
import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout.vue";
import { mapGetters } from "vuex";
import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog";
import { deptChildTree } from "@/api/reportTemplate";
import {
  lazyListUser,
  ohdocumentPage,
  ohdocumentDetail,
  ohdocumentSubmit,
} from "@/api/healthManage/healthArchives";
import { getUser } from "@/api/system/user";
import { getDictionary } from "@/api/system/dictbiz";
import {dateFormat} from "@/util/date";
import testRecords from "./testRecords.vue";
import requisition from "./requisition.vue";
import insurance from "./insurance.vue";
import hazard from "./hazard.vue";
import medicalRecord from "./medicalRecord.vue"
export default {
  components: {
    CommonTree,
    HeadLayout,
    UserDetpDialog,
    testRecords,
    requisition,
    insurance,
    hazard,
    TreeLayout,
    medicalRecord,
  },
  props: {
    isUserPage: {
      type: Boolean,
      default: false
    },
    isUserData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      nodeData: {},
      treeData: [],
      searchTitle: "deptName",
      defaultProps: {
        children: "children",
        label: "deptName",
      },
      activeName: "insurance",
      form: {
        userName: "",
        sexName: "",
        birthday: "",
        deptName: "",
        deptId: "",
        postName: "",
        emDate: "",
        bloodType: "",
        documentDate: '',
        contraindication: "",
        hazard: ""
      },
      rules: {
        documentDate: [
          { required: true, message: "请选择建档日期", trigger: "change" },

        ],
      },
      bloodTypeList: [], // 血型
      treeOptions: {
        nodeKey: "id",
        lazy: true,
        treeLoad: this.loadNode,
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          // labelText: this.$t("cip.plat.sys.user.field.title"),
          label: "deptName",
          value: "id",
          children: "children",
        },
      },
      props: {
        label: "deptName",
        value: "id",
        children: "children",
      },
      defaultExpandedKeys: [],
      loading: false,
    };
  },

  created() {
    // this.lazyDeptTree()
    // this.nodeData = this.userInfo
    getDictionary({ code: "oh_blood_type" }).then((res) => {
      this.bloodTypeList = res.data.data;
    });
  },

  mounted() {
    this.onLoad();
  },

  computed: {
    ...mapGetters(["permission", "colorName", "userInfo"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.healthArchives_add, true),
        viewBtn: this.vaildData(this.permission.healthArchives_view, true),
        editBtn: this.vaildData(this.permission.healthArchives_edit, true),
        delBtn: this.vaildData(this.permission.healthArchives_delete, true),
        saveBtn: this.vaildData(this.permission.healthArchives_save, true),
        downloadBtn: this.vaildData(
          this.permission.healthArchives_download,
          true
        ),
      };
    },
    headBtnOptions() {
      let buttonBtn = [];
      if (this.permissionList.saveBtn) {
        buttonBtn.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        });
      }
      // if (this.permissionList.downloadBtn){
      //     buttonBtn.push({
      //       label: '下载',
      //         emit: "head-download",
      //         type: "button",
      //         icon: "",
      //         btnOptType: 'download',
      //     });
      // }
      return buttonBtn;
    },
  },

  methods: {
    /** 左侧列表操作 */
    handleNodeClick(data, node) {
      if (data.type == "user") {
        this.nodeData = data;
        this.onLoad();
      }
    },

    onLoad() {
      if(Object.keys(this.isUserData).length){
        this.nodeData = this.isUserData
        this.nodeData.id = this.isUserData.userId
      }

      if (!this.nodeData.id) {
        this.nodeData = this.userInfo;
      }
      this.loading = true;
      // console.log(123,this.n odeData)
      const userId = this.nodeData.id || this.nodeData.user_id ;
      let userData = {};
      ohdocumentPage({ userId }).then((res) => {
        const data = res.data.data;
        if(this.isUserPage){
          if (data.total == 0) {
            // 无档案数据
          this.form.userId = this.isUserData.userId
          this.form.orgId = this.isUserData.deptId
           this.form.documentDate = dateFormat(new Date(),'yyyy-MM-dd')
            this.handleHeadSave()
            

          } else {
            // 有档案数据
            this.form.id = data.records[0].id;
            ohdocumentDetail({ id: data.records[0].id }).then((res) => {
              const dataInfo = res.data.data;
              this.form.orgId = dataInfo.orgId;
              this.form.orgCode = dataInfo.orgCode;
              this.form.bloodType = dataInfo.bloodType;
              this.form.documentDate = dataInfo.documentDate;
              this.form.contraindication = dataInfo.contraindication;
              this.form.hazard = dataInfo.hazard;
            });
          }
          this.loading = false;
          return
        }
        getUser(userId).then((res) => {
          userData = res.data.data;
          // console.log(userData)
          if (userData) {
            const {
              avatar,
              realName,
              sexName,
              postName,
              roleIds,
              id,
              deptName,
              deptId,
            } = userData;
            // const userName = JSON.parse(realName).zh;
            const userName = realName;
            const deptInfo = roleIds.find((val) => val.isDefaultDept == "1");
            const birthday = userData.birthday.split(" ")[0];
            this.form = {
              avatar: avatar,
              userName: userName,
              sexName: sexName,
              birthday: birthday,
              deptName: deptInfo ? deptInfo.deptName : deptName.split(",")[0],
              deptId: deptInfo ? deptInfo.deptId : deptId.split(",")[0],
              postName: postName,
              userId: id,
              bloodType: "",
              documentDate: "",
              contraindication: "",
            };
            this.loading = false;
          }
          if (data.total == 0) {
            // 无档案数据
            const deptInfo = userData.roleIds.find(
              (val) => val.isDefaultDept == "1"
            );
            this.form.orgId = deptInfo
              ? deptInfo.deptId
              : userData
              ? userData.deptId
              : "";
            this.form.orgCode = deptInfo
              ? deptInfo.deptCode
              : userData
              ? userData.deptCode
              : "";


          } else {
            // 有档案数据
            this.form.id = data.records[0].id;
            ohdocumentDetail({ id: data.records[0].id }).then((res) => {
              const dataInfo = res.data.data;
              this.form.orgId = dataInfo.orgId;
              this.form.orgCode = dataInfo.orgCode;
              this.form.bloodType = dataInfo.bloodType;
              this.form.documentDate = dataInfo.documentDate;
              this.form.contraindication = dataInfo.contraindication;
              this.form.hazard = dataInfo.hazard;
            });
          }
        });
      });
    },

    async handleHeadSave() {

      this.$refs.form.validate((valid) => {
        if (valid) {
          const {
            bloodType,
            contraindication,
            documentDate,
            userName,
            userId,
            orgId,hazard,
            orgCode,
          } = this.form;
          let params = {
            bloodType,hazard,
            contraindication,
            documentDate,
            userName,
            userId,
            orgId,
            orgCode,
          };
          if (this.form.id) params.id = this.form.id;
          ohdocumentSubmit(params).then((res) => {
            this.$message.success("保存成功");
            this.onLoad();
          });
        }
      });
    },
    handleHeadDownload() {},
    handleClick(val) {},
    loadNode(node, resolve) {
      console.log(this.userInfo);
      if (node.level === 0) {
        resolve([
          {
            deptName: this.userInfo.dept_name,
            id: this.userInfo.dept_id,
            type: "",
            leaf: false,
          },
        ]);
      } else {
        let params = { parentId: node.data.id };
        lazyListUser(params).then((res) => {
          resolve(
            res.data.data.map((item) => {
              return {
                ...item,
                leaf: !item.hasChildren,
              };
            })
          );
          this.$nextTick(() => {
            res.data.data.forEach((item) => {
              if (item.id == this.nodeData.id) {
                this.$refs.treeLayout.$refs.avueTree.setCurrentKey(item.id);
              }
            });
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.common_tree_handle {
  width: 280px;
  // padding-right: 12px;
  box-sizing: border-box;
  border-right: 1px solid #cccccc;
  background: #ffffff;
  flex-shrink: 0;
}
.common_tree_handle {
  .el-tree {
    /* height:100% !important; */
    height: calc(100vh - 300px) !important;
    overflow-y: scroll;
  }
}

::v-deep .el-tree-node__content {
  line-height: 26px;
}
</style>
