<template>
    <el-container>
        <el-main>
            <head-layout
                head-title="职业病病历"
                :head-btn-options="headBtnOptions"
                @head-add="headAdd"
                @head-remove="rowRemove"
            />
            <grid-layout
                ref="gridLayOut"
                :grid-row-btn="gridRowBtn"
                :table-options="tableOptions"
                :table-data="tableData"
                :table-loading="tableLoading"
                :data-total="page.total"
                :page="page"
                :editType="inline"
                @page-current-change="handleCurrentChange"
                @page-size-change="handleSizeChange"
                @page-refresh-change="onLoad"
                @gird-handle-select-click="selectionChange"
                @row-edit="rowEdit"
                @row-remove="rowRemove"
            > 
                <template #documentUrl="{row,index}">
                    <el-link type="primary" v-for="(item,i) in getFiles(row.documentUrl)" :key="item.url" :href="item.url">{{ item.name }}{{ i == getFiles(row.documentUrl).length-1 ? '' : ',' }}</el-link>
                </template>
            </grid-layout>
            <!-- 操作页 -->
            <el-dialog :visible.sync="dialogVisible" :title="title" :before-close="handleCancel">
                <avue-form :option="dialogOption" ref="cedForm" v-model="form" @submit="handleSubmit">
                    <template slot="documentUrl">
                        <el-upload
                          v-if="formType != 'view'"
                          accept=".zip,.doc,.docx,.pdf,.jpg,.png"
                          style="margin-bottom: 12px; align-items: center;"
                          class="upload-demo"
                          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"
                          :on-success="handleDocSuccess"
                          ref="uploadRef"
                          :headers="headers"
                          :file-list=files
                          :on-remove="handleDocRemove"
                          :before-upload="handleBeforeUpload"
                          >
                          <!-- :show-file-list="false" -->
                        <div slot="tip" class="el-upload__tip">
                            支持*.zip/*.doc/*.docx/*.pdf/*.jpg/*.png格式,文件大小不超过5M
                        </div>
                          <el-button
                            size="small"
                            type="primary"
                            icon="el-icon-upload"
                          >点击上传
                          </el-button>
                        </el-upload>
                    </template>
                </avue-form>
                <template slot="footer">
                    <el-button size="small" @click="handleCancel()" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.celBtn`) }} </el-button>
                    <el-button size="small" type="primary" @click="handleSave" v-loading="dialogLoading" > {{ $t(`cip.cmn.btn.defBtn`) }} </el-button>
                </template>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import {medicalRecordPage, medicalRecordSubmit, medicalRecordRemove} from '@/api/healthManage/medicalRecord'
import {mapGetters} from 'vuex'
import {dateFormat} from "@/util/date";
import { getDictionary } from "@/api/system/dictbiz";

export default {
    props: {
        active: {
            type: String,
            default: ''
        },
        documentInfo: {
            type: Object,
            default: ()=>{}
        }
    },

    components: {
        HeadLayout,
        GridLayout,
    },

    data () {
        return {
            headTitle: '',
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            selectionList: [],
            query: {},
            dialogVisible: false,
            title: '',
            form: {},
            files: [],
            diseaseList: []
        }
    },

    watch: {
        active: {
            handler () {
                this.init()
            },
            immediate: true,
        },
        "documentInfo.id" : {
            handler () {
                this.init()
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters(["permission", 'userInfo']),

        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.medicalRecord_add, true),
                editBtn: this.vaildData(this.permission.medicalRecord_edit, true),
                delBtn: this.vaildData(this.permission.medicalRecord_delete, true),
            };
        },
        headBtnOptions () {
            let btnList = []
                btnList.push({
                    emit: "head-add",
                    type: "button",
                    icon: "",
                    btnOptType: 'add',
                });
                btnList.push({
                    label: '移除',
                    emit: "head-remove",
                    type: "button",
                    icon: "",
                    btnOptType: 'remove',
                });
            return btnList
        },

        tableOptions () {
            return {
                selection: true,
                column: [
                    {
                        label: "职业病",
                        prop: "disease",
                        align: "center",
                        type: 'select',
                        // dicUrl:"/api/sinoma-system/dict-biz/dictionary?code=occupational_type",
                        // props: {
                        //     label: "dictValue",
                        //     value: "dictKey",
                        // },
                        overHidden: true,
                        formatter: (row, value, column) => {
                            console.log(value.split(','),this.diseaseList)
                            const arr = value.split(',')
                            const str = arr.map(val => {
                                 let data = this.diseaseList.find(v => v.dictKey == val)
                                return data ? data.dictValue : ''
                            })
                            return str.toString()
                        }
                    },
                    {
                        label: "检查单位",
                        prop: "testOrg",
                        align: "center",
                        overHidden: true,
                    },
                    {
                        label: "检查日期",
                        prop: "testDate",
                        align: "center",
                        overHidden: true,
                        width: '120px'
                    },
                    {
                        label: "诊断证明",
                        prop: "documentUrl",
                        align: "center",
                        overHidden: true,
                        slot: true,
                    },
                ]
            }
        },

        gridRowBtn () {
            let btnList = []
            if (this.permissionList.editBtn){
                btnList.push({
                    label: '编辑',
                    emit: "row-edit",
                    type: "button",
                    icon: "",
                    btnOptType: 'edit',
                });
            }
            if (this.permissionList.delBtn){
                btnList.push({
                    label: '删除',
                    emit: "row-remove",
                    type: "button",
                    icon: "",
                    btnOptType: 'del',
                });
            }
            return btnList
        },

        dialogOption () {
            return {
                size: 'small',
                submitBtn: false,
                emptyBtn: false,
                labelWidth: 120,
                column: [
                    {
                        label: "职业病",
                        prop: "disease",
                        placeholder: '请选择',
                        span: 12,
                        type: "cascader",
                        dicUrl:"/api/sinoma-system/dict-biz/dictionary-tree?code=occupational_type",
                        props: {
                            label: "dictValue",
                            value: "dictKey",
                        },
                        multiple: true,
                        emitPath: false,
                        rules: [{
                            required: true,
                            message: '请选择职业病',
                            trigger: 'change',
                        }]
                    },
                    {
                        label: "检查单位",
                        prop: "testOrg",
                        placeholder: '请输入检查单位',
                        span: 12,
                        maxlength: 200,
                        rules: [{
                            required: true,
                            message: '请输入检查单位',
                            trigger: 'bulr'
                        }]
                    },
                    {
                        label: "检查日期",
                        prop: "testDate",
                        labelWidth: 120,
                        placeholder: "请选择",
                        span: 12,
                        type: 'date',
                        format: 'yyyy-MM-dd', // 设置日期格式
                        valueFormat: 'yyyy-MM-dd', // 设置数据库中存储的日期格式
                        rules: [{
                            required: true,
                            message: "请选择",
                            trigger: "change"
                        }],
                    },
                    {
                        label: "诊断证明",
                        prop: "documentUrl",
                        type: 'textarea',
                        span: 24,
                        formslot: true,
                    }
                ]
            }
        },
    },

    created () {
        getDictionary({code: 'occupational_type'}).then((res) => {
            this.diseaseList = res.data.data;
        });
    },

    methods: {
        init () {
            this.page.current = 1 
            this.page.pageSize = 20 
            this.onLoad(this.page)
        },
        headAdd () {
            this.title = '职业病病历新增'
            this.dialogVisible = true
        },
        rowRemove (row,index) {
            let ids = ''
            if(row) {
                ids = row.id
            } else {
                ids = this.selectionList.map(val=>val.id).toString()
                if(!ids){
                    this.$message.warning('请选择操作数据')
                }
            }
            if(ids){
                this.$confirm("确认删除当前数据吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    medicalRecordRemove(ids).then((res) => {
                        if (res.data.code == 200) {
                            this.$message.success("删除成功")
                            this.onLoad(this.page);
                        }
                    });
                }).catch((error) => {
                        this.$message({
                            message: "取消删除",
                            type: "info",
                        });
                    });
            }
        },

        onLoad (page,params={}) {
            this.tableLoading = true; 
            this.page = page;
            this.query = {userId: this.documentInfo.userId}
            medicalRecordPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.tableData = data.records;
                this.tableLoading = false;
                this.$refs.gridLayOut.selectionClear()
            });
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page)
        },
        selectionChange (list) {
            this.selectionList = list
        },

        rowEdit (row, index) {
            this.form = row
            this.form.disease = row.disease.split(",")
            this.files = this.getFiles(row.documentUrl)
            this.title = '职业病病历编辑'
            this.dialogVisible = true
        },

        getFiles (docUrl) {
            // console.log(docUrl)
            const fileUrl = docUrl ? JSON.parse(docUrl) : [] 
            let files = fileUrl.map(val => {
                // let pos = val.lastIndexOf("/")
                return {
                    // name: val.substring(pos+1, val.length),
                    name: val.name,
                    url: val.url
                }
            })
            return files
        },

        handleCancel () {
            this.$refs.cedForm.resetForm()
            delete this.form.id
            this.files =[]
            this.dialogVisible = false
        },
        handleSubmit (data,done) {
            this.$refs.cedForm.validate(valid=>{
                if(valid) {
                    this.dialogLoading = true
                    this.form.userId = this.documentInfo.userId
                    this.form.documentUrl = JSON.stringify(this.files) 
                    this.form.disease = this.form.disease.toString()
                    medicalRecordSubmit(this.form).then(() => {
                        // this.onLoad()
                        this.$message.success('操作成功')
                        this.dialogLoading = false
                        this.handleCancel()
                        this.init()
                        done()
                    }).catch(err => {
                        done()
                    }).finally(() => {
                        this.dialogLoading = false
                    })
                }
            })
        },
        handleSave () {
            this.$refs.cedForm.submit();
        },

        // 上传之前的函数
        handleBeforeUpload(file) {
            let fileName = file.name;
            let pos = fileName.lastIndexOf(".");
            let lastName = fileName.substring(pos, fileName.length).toLowerCase();
            let arr = [".zip", ".doc", ".docx", ".pdf", ".jpg", ".png"];
            const isLt20M = file.size / 1024 / 1024 < 5;
            if (!isLt20M) {
                this.$message.warning("上传文件大小不能超过 5MB!");
                return false;
            }
            if (!arr.includes(lastName)) {
                this.$message.warning("不支持上传此类型文件");
                return false;
            }
        },

        handleDocSuccess(res, file, fileList) {
            this.files = [
                {
                    name: file.response.data.originalName,
                    url: file.response.data.link,
                } 
            ]
        },

        handleDocRemove (file,fileList) {
            this.files = fileList.map(val=>{
                return{
                    name: val.name,
                    url: val.url
                } 
            })
        },

        overDue (date) {
            if(date){
                let newDate = new Date(dateFormat(new Date(), 'yyyy-MM-dd')).getTime()
                let str = new Date(date).getTime()
                return str<newDate
            }
            return false
        },
    }
}
</script>

<style lang="scss" scoped>
.overDue{
    color: #C5211B;
}
</style>