var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "basic-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.dialogLoading,
          expression: "dialogLoading",
        },
      ],
      staticClass: "addThirduser",
    },
    [
      !_vm.dialogLoading
        ? _c(
            "div",
            { staticClass: "shuttleBackBox" },
            [
              _c(
                "head-layout",
                {
                  staticClass: "titleSuffix",
                  attrs: {
                    row: _vm.form,
                    "head-title": "人员信息",
                    "head-btn-options":
                      _vm.pageType == "view"
                        ? _vm.viewOption
                        : _vm.userBtnOptions,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "titleSuffix" },
                    [
                      _c(
                        "el-tag",
                        {
                          staticClass: "hdStatus",
                          style: {
                            backgroundColor: _vm.StatusIcon[_vm.form.isUsed],
                            borderColor: _vm.borderDic[_vm.form.isUsed],
                            color: _vm.borderDic[_vm.form.isUsed],
                          },
                          attrs: { effect: "dark" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getIsUsed(
                                  _vm.form.isUsed,
                                  _vm.third_user_status
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "main-box" },
                [
                  _c(
                    "div",
                    { staticClass: "flex-container form-box" },
                    [
                      _c(
                        "avue-form",
                        {
                          ref: "addForm",
                          attrs: {
                            "upload-delete": _vm.uploadDelete1,
                            "upload-after": _vm.uploadAfter1,
                            "upload-exceed": _vm.uploadExceed,
                            option: _vm.options,
                          },
                          on: { submit: _vm.treeNodeSave },
                          model: {
                            value: _vm.form,
                            callback: function ($$v) {
                              _vm.form = $$v
                            },
                            expression: "form",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "stPostId" },
                            [
                              _c("avue-input-tree", {
                                attrs: {
                                  "popper-class": "notDisabled",
                                  parent: false,
                                  props: _vm.treeProps,
                                  disabled: _vm.disabledFlg,
                                  clearable: "",
                                  nodeClick: _vm.handlePost,
                                  placeholder: "请选择工种",
                                  dic: _vm.postList,
                                },
                                model: {
                                  value: _vm.form.stPostId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "stPostId", $$v)
                                  },
                                  expression: "form.stPostId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("template", { slot: "age" }, [
                            _vm._v(
                              "\n           " +
                                _vm._s(_vm.getAge(_vm.form)) +
                                "\n         "
                            ),
                          ]),
                          _c(
                            "template",
                            { slot: "userName" },
                            [
                              _c("el-autocomplete", {
                                attrs: {
                                  disabled: !_vm.disabledFun,
                                  placeholder: "请输入姓名",
                                  "fetch-suggestions": _vm.querySearch,
                                  "trigger-on-focus": false,
                                  maxlength: "50",
                                },
                                on: { select: _vm.handleSelect },
                                model: {
                                  value: _vm.form.userName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "userName", $$v)
                                  },
                                  expression: "form.userName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "template",
                            { slot: "idCoding" },
                            [
                              _c("el-autocomplete", {
                                attrs: {
                                  disabled: !_vm.disabledFun,
                                  placeholder: "请输入身份证号",
                                  "fetch-suggestions": _vm.queryCodeSearch,
                                  "trigger-on-focus": false,
                                },
                                on: { select: _vm.handleSelect },
                                model: {
                                  value: _vm.form.idCoding,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "idCoding", $$v)
                                  },
                                  expression: "form.idCoding",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("template", { slot: "refuseRemark" }, [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.form.refuseRemark == -1
                                    ? ""
                                    : _vm.form.refuseRemark
                                )
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                      _vm.qrcodeSrc
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                width: "400px",
                                "text-align": "center",
                              },
                            },
                            [
                              _c("el-image", {
                                staticStyle: {
                                  width: "250px",
                                  height: "250px",
                                },
                                attrs: { src: _vm.qrcodeSrc },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "80%" },
                                  attrs: { size: "mini" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.downloadQrcode.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("下载\n         ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-tabs",
                    {
                      attrs: {
                        type: "border-card",
                        "before-leave": _vm.handleClick,
                      },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: { label: "人员证照", name: "ryzz" },
                      }),
                      _vm.pageType != "add"
                        ? _c("el-tab-pane", {
                            attrs: { label: "培训记录", name: "pxjl" },
                          })
                        : _vm._e(),
                      _vm.pageType != "add"
                        ? _c("el-tab-pane", {
                            attrs: { label: "项目记录", name: "xmjl" },
                          })
                        : _vm._e(),
                      _vm.pageType != "add"
                        ? _c("el-tab-pane", {
                            attrs: { label: "奖惩记录", name: "jcjl" },
                          })
                        : _vm._e(),
                      _vm.pageType != "add"
                        ? _c("el-tab-pane", {
                            attrs: { label: "进出场记录", name: "jccjl" },
                          })
                        : _vm._e(),
                      _vm.pageType != "add" && _vm.form.thirdFlg
                        ? _c("el-tab-pane", {
                            attrs: { label: "健康档案", name: "jkda" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-box" },
                    [
                      _vm.activeName == "ryzz" &&
                      _vm.selectOption.column.length != 0
                        ? _c("avue-form", {
                            staticClass: "form-item",
                            attrs: {
                              "upload-delete": _vm.uploadDelete,
                              "upload-after": _vm.uploadAfter,
                              option: {
                                ..._vm.selectOption,
                                disabled: !_vm.fileDisable,
                              },
                              "upload-error": _vm.uploadSized,
                            },
                            model: {
                              value: _vm.fileForm,
                              callback: function ($$v) {
                                _vm.fileForm = $$v
                              },
                              expression: "fileForm",
                            },
                          })
                        : _vm._e(),
                      _vm.activeName == "ryzz"
                        ? _c("avue-form", {
                            class:
                              _vm.selectOption.column.length == 0
                                ? "form-item"
                                : "",
                            attrs: {
                              "upload-delete": _vm.uploadDelete,
                              "upload-after": _vm.uploadAfter,
                              option: {
                                ..._vm.userOption,
                                disabled: !_vm.fileDisable,
                              },
                              "upload-error": _vm.uploadSized,
                            },
                            model: {
                              value: _vm.fileForm,
                              callback: function ($$v) {
                                _vm.fileForm = $$v
                              },
                              expression: "fileForm",
                            },
                          })
                        : _vm._e(),
                      _vm.activeName != "ryzz" &&
                      _vm.showPage &&
                      _vm.activeName != "jkda"
                        ? _c("avue-crud", {
                            staticClass: "showPage",
                            attrs: {
                              option: {
                                ..._vm.option[_vm.activeName],
                                ..._vm.optionDict,
                              },
                              data: _vm.tableData,
                              search: _vm.search,
                            },
                            on: {
                              "search-change": _vm.searchChange,
                              "search-reset": _vm.searchReset,
                              "update:search": function ($event) {
                                _vm.search = $event
                              },
                              "current-change": _vm.currentChange,
                              "size-change": _vm.sizeChange,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "courseCover",
                                  fn: function ({ row }) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          staticStyle: {
                                            "text-decoration": "none",
                                          },
                                          style: { color: _vm.themeColor },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowRouter(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n             " +
                                              _vm._s(row.courseCover) +
                                              "\n           "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1281613913
                            ),
                          })
                        : _vm._e(),
                      _vm.activeName == "jkda" && _vm.form.thirdFlg
                        ? _c("healthArchivesPage", {
                            ref: "healthArchives",
                            attrs: { isUserData: _vm.form, isUserPage: "" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("qrcodeDialog", { ref: "qrcodeDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }