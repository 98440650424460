<template>
    <el-container>
        <el-main>

            <head-layout
                :head-title="''"
                :head-btn-options="headBtnOptions"
                @head-reg="headReg"
            />
            <grid-layout
                ref="gridLayOut"
                :table-options="tableOptions"
                :table-data="tableData"
                :table-loading="tableLoading"
                :data-total="page.total"
                :page="page"
                :editType="inline"
                @page-current-change="handleCurrentChange"
                @page-size-change="handleSizeChange"
                @page-refresh-change="onLoad"
                @gird-handle-select-click="selectionChange"
            >
                <template #hcCode="{row,index}">
                    <el-link type="primary" @click="headRegDetail(row)" >{{ row.hcCode }}</el-link>
                </template>
            </grid-layout>
            
        </el-main>
    </el-container>
</template>

<script>
import HeadLayout from '@/views/components/layout/head-layout.vue'
import GridLayout from "@/views/components/layout/grid-layout.vue";
import { ohhcPage } from '@/api/healthManage/testRecords'

export default {
    props: {
        active: {
            type: String,
            default: ''
        },
        documentInfo: {
            type: Object,
            default: ()=>{}
        }
    },

    components: {
        HeadLayout,
        GridLayout
    },

    data () {
        return {
            headTitle: '',
            tableData: [],
            tableLoading: false,
            page: {
                pageSize: 20,
                currentPage: 1,
                total: 0
            },
            selectionList: [],
            query: {},
            // active: '',
        }
    },

    watch: {
        active: {
            handler (val) {
                this.init()
            },
            immediate: true,
        }
    },

    computed: {
        headBtnOptions () {
            let btnList = []
            btnList.push({
                    label: '体检登记',
                    emit: "head-reg",
                    type: "button",
                    icon: "",
                });
            return btnList
        },

        tableOptions () {
            return {
                selection: false,
                menu: false,
                linklabel: 'hcCode',
                column: [
                  {
                      label: "记录编号",
                      prop: "hcCode",
                      align: "center",
                      overHidden: true,
                      slot: true,
                  },
                  {
                      label: "体检类型",
                      prop: "hcType",
                      align: "center",
                      overHidden: true,
                      dicUrl:"/api/sinoma-system/dict-biz/dictionary-tree?code=oh_hc_type",
                      props: {
                        label: "dictValue",
                        value: "dictKey",
                      },
                  },
                  {
                      label: "体检日期",
                      prop: "hcDate",
                      align: "center",
                      overHidden: true,
                      width: '120px',
                  },
                  {
                      label: "体检机构",
                      prop: "hcOrg",
                      align: "center",
                      overHidden: true,
                  },
                ]
            }
        },
    },

    mounted () {
        // this.init()
    },
    

    methods: {
        init () {
            this.page.current = 1 
            this.page.pageSize = 20 
            this.onLoad(this.page)
        },
        headReg () {
            const { userId,userName,deptName,deptId} =  this.documentInfo
            this.$router.push({
                path: '/business/healthManage/testRecords/form',
                query: {
                    type: 'add',
                    row: JSON.stringify({userId,userName,deptName,deptId}),
                }
            })
        },

        onLoad (page,params={}) {
            this.tableLoading = true; 
            this.page = page;
            this.query = {userId: this.documentInfo.userId}
            ohhcPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.$refs.gridLayOut.page.total = data.total;
                this.tableData = data.records;
                this.tableLoading = false;
                this.$refs.gridLayOut.selectionClear()
            });
        },

        //页数改变
        handleCurrentChange(e) {
            this.page.currentPage = e.currentPage
            this.onLoad(this.page)
        },
        //size改变
        handleSizeChange(e) {
            this.page.pageSize = e.pageSize
            this.onLoad(this.page)
        },
        selectionChange (list) {
            this.selectionList = list
        },

        headRegDetail (row) {
            this.$router.push({
                path: '/business/healthManage/testRecords/form',
                query: {
                    row: JSON.stringify(row),
                    type: 'view'
                }
            })
        }
    }
}
</script>

<style>

</style>