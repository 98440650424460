<template>
  <basic-container v-loading="dialogLoading" class=" addThirduser">
    <div class="shuttleBackBox" v-if="!dialogLoading">
      <head-layout  :row="form" class="titleSuffix" head-title="人员信息"
                   :head-btn-options=" pageType == 'view' ? viewOption: userBtnOptions">
        <template slot="titleSuffix">
          <el-tag effect="dark" class="hdStatus"
                  :style="{backgroundColor:StatusIcon[form.isUsed],borderColor:borderDic[form.isUsed],color:borderDic[form.isUsed]}">
            {{ getIsUsed(form.isUsed, third_user_status) }}
          </el-tag>
        </template>
      </head-layout>
     <div class="main-box">
       <div class="flex-container form-box">
         <avue-form :upload-delete="uploadDelete1" :upload-after="uploadAfter1" :upload-exceed="uploadExceed"
                    :option="options" ref="addForm" v-model="form" @submit="treeNodeSave">
           <template slot="stPostId">
             <avue-input-tree popper-class="notDisabled" :parent="false" :props="treeProps" :disabled="disabledFlg"
                              clearable :nodeClick="handlePost" v-model="form.stPostId"
                              placeholder="请选择工种" :dic="postList">
             </avue-input-tree>
           </template>
           <template slot="age" >
             {{getAge(form)}}
           </template>
           <template slot="userName">
             <el-autocomplete :disabled="!disabledFun" placeholder="请输入姓名" v-model="form.userName"
                              :fetch-suggestions="querySearch"
                              :trigger-on-focus="false" @select="handleSelect" maxlength="50">
             </el-autocomplete>
           </template>
           <template slot="idCoding">
             <el-autocomplete :disabled="!disabledFun" placeholder="请输入身份证号" v-model="form.idCoding"
                              :fetch-suggestions="queryCodeSearch"
                              :trigger-on-focus="false" @select="handleSelect">
             </el-autocomplete>
           </template>
           <!--          <template slot="birthday">-->
           <!--            <el-input :style="itemObject(form)" disabled :value="getAge(form)"></el-input>-->
           <!--          </template>-->
           <template slot="refuseRemark">
             <span style="color:red;">{{ form.refuseRemark == -1 ? '' : form.refuseRemark }}</span>
           </template>
         </avue-form>
         <div
           v-if="qrcodeSrc"
           style=" width: 400px;text-align: center;">
           <el-image
             :src="qrcodeSrc"
             style="width: 250px; height: 250px"
           ></el-image>
           <el-button style="width: 80%;" size="mini" @click.native="downloadQrcode"
           >下载
           </el-button>
         </div>
       </div>
       <el-tabs type="border-card" v-model="activeName" :before-leave="handleClick">
         <el-tab-pane label="人员证照" name="ryzz"></el-tab-pane>
         <el-tab-pane v-if="pageType != 'add'" label="培训记录" name="pxjl"></el-tab-pane>
         <el-tab-pane v-if="pageType != 'add'" label="项目记录" name="xmjl"></el-tab-pane>
         <el-tab-pane v-if="pageType != 'add'" label="奖惩记录" name="jcjl"></el-tab-pane>
         <el-tab-pane v-if="pageType != 'add'" label="进出场记录" name="jccjl"></el-tab-pane>
         <el-tab-pane v-if="pageType != 'add'&&form.thirdFlg" label="健康档案" name="jkda"></el-tab-pane>
       </el-tabs>
       <div class="form-box">
        <!--        <head-layout v-if="activeName == 'ryzz' && disabledFun"-->
         <!--                     :head-btn-options="headBtnOptions" @head-save="headSave"></head-layout>-->
            <avue-form class="form-item" :upload-delete="uploadDelete"
                        v-if="activeName == 'ryzz' && selectOption.column.length != 0" :upload-after="uploadAfter" :option="{
              ...selectOption,
              disabled:!fileDisable,
            }" v-model="fileForm" :upload-error="uploadSized"></avue-form>
            <avue-form :class="selectOption.column.length == 0 ? 'form-item' :''" :upload-delete="uploadDelete"
                        v-if="activeName == 'ryzz'" :upload-after="uploadAfter" :option="{
          ...userOption,
          disabled:!fileDisable,
        }" v-model="fileForm"
                    :upload-error="uploadSized"></avue-form>
         <avue-crud class=" showPage" v-if="activeName != 'ryzz' &&  showPage &&activeName != 'jkda'"
                    :option="{...option[activeName],...optionDict}"
                    :data="tableData" @search-change="searchChange" @search-reset="searchReset" :search.sync="search"
                    @current-change="currentChange" @size-change="sizeChange">
           <template slot="courseCover" slot-scope="{row}">
             <el-link :style="{color: themeColor}" style="text-decoration: none;" @click="rowRouter(row)">
               {{ row.courseCover }}
             </el-link>
           </template>
         </avue-crud>
        <healthArchivesPage :isUserData="form" isUserPage v-if="activeName == 'jkda'&&form.thirdFlg" ref="healthArchives"></healthArchivesPage>

       </div>
     </div>
    </div>
    <!-- 加入项目扫描二维码弹框 -->
    <qrcodeDialog ref="qrcodeDialog"></qrcodeDialog>
  </basic-container>
</template>

<script>
import healthArchivesPage from "@/views/business/healthManage/healthArchives/index.vue"
import {
  fileUserSubmit, getFileList, getFileUserList, prjthirduserDetail,
  prjthirduserSubmit, stpostList1
} from "@/api/safe/itemList";
import {
  getList
} from "@/api/safe/thirduser";
import {
  calculateAge,
  getAgeFromIdCard,
  getBirthDate
} from "@/util/util";
import {
  PATTERN_ID_CARD
} from "@/util/validate"
import {from} from "rxjs";
import Template from "@/views/message/template/list.vue";
import organization from "@/views/business/safe/itemList/organization";
import {mapGetters} from "vuex";
import {cateList, stpostList} from "@/api/safetyTtandard/occupationConfig";
import qrcodeDialog from "@/views/projectSettings/regionManage/qrcode.vue";
import {classificationRoleGetList} from "@/api/system/role";
import {HD_SOURCE_TYPE} from "@/api/riskitemView/evaluate";


export default {
  components: {qrcodeDialog, Template,healthArchivesPage},
  mixins: [organization],
  data() {
    return {
      third_user_status:[],
      project_sex_type: {},
      treeProps: {
        label: 'postName',
        value: 'id',
      },
      StatusIcon: {
        '0': 'rgb(238, 248, 232)',
        '1': 'rgb(242, 242, 243)',
        '2': 'rgb(233, 243, 255)',
        '3': 'rgb(254, 238, 238)',
      },
      borderDic: {
        '0': 'rgb(122, 199, 86)',
        '1': 'rgb(133, 136, 142)',
        '2': 'rgb(56, 148, 255)',
        '3': 'rgb(242, 97, 97)',
      },
      qrcodeSrc: '',
      postList: [],
      APITYPE: 'user',
      activeName: 'ryzz',
      showDialog: false,

      treeReadOnlyFlag: false,
      form: {
        birthday: '',
        userId: '',
        id: '',
        updateTime: '',
        updateUser: '',
        isUsed: '2',
        recentPhoto: [],
        idCodingImg: [],
        idCodingImg1: [],
      },
      title: '',
      isEdit: false,
      searchData: [],
      viewOption: [
        {
          label: this.$t(`cip.cmn.btn.celBtn`),
          show: (row) => {
            return this.APITYPE == 'user'
          },
          handle: () => {
            this.closeDialog()
          },
          type: "button",
          btnOptType: "default",
        }
      ],
      pageType: '',
      fileAppend: {
        label: '',
        prop: '',
        type: 'upload',
        props: {
          label: 'originalName',
          value: 'thumbnailLink'
        },
        dataType: 'object',
        listType: 'picture-card',
        accept: 'image/*',
        fileSize: 5000,
        span: 24,
        propsHttp: {
          res: 'data'
        },
        tip: '图片支持*.jpg/*.png 格式，文件大小不超过5M',
        action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km'
      },
    }

  },
  computed: {
    disabledFun() {
      if (this.pageType == 'view') return false
      if (this.thirdFlg && (this.pageType == 'edit')) {
        return true
      } else {
        return this.pageType == 'edit' ? false : true
      }
    },
    fileDisable(){
      if (this.pageType == 'view') return false
      return true
    },
    disabledFlg() {
      return this.pageType == 'view' ? true : false
    },
    thirdFlg() {
      return this.form.thirdFlg == 1
    },
    ...mapGetters([
      'userInfo',
    ]),
    options() {
      let that = this;
      return {
        size: 'small',
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 140,
        column: [
          {
            label: "姓名",
            span: 12,
            disabled: !this.disabledFun,
            prop: "userName",
            rules: [{
              required: true,
              message: "请输入姓名",
              trigger: "blur"
            }, {max: 50, message: '姓名不能超过50个字符', trigger: 'blur'}]
          },
          {
            span: 12,
            label: "国籍",
            disabled: !this.disabledFun,
            prop: "nationality",
            value:'5651',
            filterable:true,
            type: "select",
            dataType: 'string',
            props: {
              label: "name",
              value: "code",
            },
            dicUrl: "/api/sinoma-system/region/selectByLevel?regionLevel=1",
            rules: [
              {required: this.thirdFlg, message: "国籍不能为空", trigger: "change"},
            ]
          },
          {
            span: 12,
            disabled: !this.disabledFun,
            label: "身份证号",
            prop: "idCoding",
            rules: [{
              required: this.thirdFlg,
              validator:this.form.nationality == '5651'? this.validateIdCard :undefined,
              trigger: 'blur'
            }]
          },
          {
            span: 12,
            label: "年龄",
            prop: "age",
          },
          {
            span: 12,
            disabled: !this.disabledFun,
            label: "出生日期",
            prop: "birthday",
            format:"yyyy-MM-dd",
            valueFormat:"yyyy-MM-dd",
            type:'date',
            rules: [{
              required: this.thirdFlg,
              message: '出生日期不能为空',
              trigger: 'change'
            }]
          },
          {
            span: 12,
            label: "电话号码",
            prop: "phone",
            disabled: !this.disabledFun,
            placeholder: "请输入电话号码",
            rules: [
              {
                required: this.thirdFlg,
                message: "请输入电话号码",
                trigger: "blur",
              },
              {pattern: this.form.nationality == '5651'? /^1[3456789]\d{9}$/ :null, message: "电话号码格式错误"},
            ],
          },
          {
            span: 12,
            display: false,
            label: "登录账号",
            prop: "account",
            placeholder: "请输入登录账号",
            rules: [
              {
                required: this.thirdFlg,
                message: "请输入登录账号",
                trigger: "blur",
              },
              // {pattern: /^1[3456789]\d{9}$/, message: "电话号码格式错误"},
            ],
          },
          {
            span: 12,
            label: "性别",
            prop: "sex",
            disabled: !this.disabledFun,
            type: "select",
            dataType: 'string',
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict/dictionary?code=gender",
            dicFormatter:(res)=>{
              this.project_sex_type=Object.fromEntries(res.data.map(item => [item.dictValue, item.dictKey]));
              return res.data
            },
            rules: [{
              required: this.thirdFlg,
              message: "请选择性别",
              trigger: "blur"
            }]
          },
          {
            span: 12,
            disabled: !this.disabledFun,
            label: "籍贯",
            prop: "domicile",
            rules: [{max: 50, message: '籍贯不能超过50个字符', trigger: 'blur'}]
          },
          {
            span: 12,
            label: "工种/岗位",
            prop: "stPostId",
            formslot: true,
            rules: [{
              required: true,
              message: "请选择工种",
              trigger: "blur"
            }, {max: 20, message: '工种不能超过20个字符', trigger: 'change'}]
          },
          {
            span: 12,
            label: "所属组织",
            prop: "deptId",
            popperClass:"notDisabled",
            disabled: this.disabledFlg,
            type: "tree",
            dataType: 'string',
            props: {
              label: "title",
              value: "id",
            },
            dicUrl: `/api/sinoma-system/dept/tree?parentId=${this.$route.query.prjId}`,
            dicFormatter: (res) => {
              let resp = that.filterTree(res.data)
              return resp
            },
            rules: [{
              required: true,
              message: "请选择所属组织",
              trigger: "blur"
            }]
          },
          {
            span: 24,
            label: "角色",
            prop: "roleIds",
            multiple: true,
            display: !this.thirdFlg,
            disabled: this.disabledFlg,
            type: "select",
            dataType: 'string',
            props: {
              label: "roleName",
              value: "id",
            },
            dicMethod:'post',
            dicUrl: "/api/sinoma-system/role/listPost?role_group_type=1",
            rules: [{
              required: !this.thirdFlg,
              message: "请选择角色",
              trigger: "blur"
            }]
          },
          {
            span: 6,
            disabled: !this.disabledFun,
            label: '是否通过教育',
            prop: 'isExam',
            value: 1,
            type: 'switch',
            dicData: [{
              label: '',
              value: 0
            }, {
              label: '',
              value: 1
            }]
          },
          {
            span: 6,
            disabled: !this.disabledFun,
            label: '紧急联系电话',
            placeholder: '请输入电话号码',
            prop: 'emergencyPhone',
            // rules: [
            //   {
            //     required: true,
            //     message: "请输入紧急联系电话号码",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            span: 6,
            disabled: !this.disabledFun,
            label: '是否特种人员',
            prop: 'isSpecial',
            type: 'switch',
            dicData: [{
              label: '',
              value: 0
            }, {
              label: '',
              value: 1
            }]
          },
          {
            span: 6,
            // disabled: !this.disabledFun,
            label: '是否常驻',
            prop: 'isPermanent',
            type: 'switch',
            dicData: [{
              label: '',
              value: 0
            }, {
              label: '',
              value: 1
            }]
          },
          {

            label: '拒绝原因',
            span: 24,
            display: this.form.refuseRemark ? true : false,
            formslot: true,
            prop: 'refuseRemark',
          },
          {
            disabled: !this.fileDisable,
            loadText: '身份证信息识别中，请稍等',
            label: '身份证正面',
            prop: 'idCodingImg',
            class: 'idCodingImg1',
            type: 'upload',
            limit: 1,
            props: {
              label: 'originalName',
              value: 'thumbnailLink'
            },
            dataType: 'object',
            listType: 'picture-card',
            accept: 'image/*',
            fileSize: 5000,
            span: 12,
            propsHttp: {
              res: 'data'
            },
            data: {
              side: 'front',
            },
            tip: '请上传身份证正面',
            action: '/api/sinoma-hse-prj/ocr/uploadIdCard',
            rules: [
              {
                required: false,
                message: "请上传身份证正面",
                trigger: "change",
              },
            ],
          }, {
            disabled: !this.fileDisable,
            limit: 1,
            label: '身份证背面',
            class: 'idCodingImg1',
            prop: 'idCodingImg1',
            type: 'upload',
            props: {
              label: 'originalName',
              value: 'thumbnailLink'
            },
            dataType: 'object',
            listType: 'picture-card',
            accept: 'image/*',
            fileSize: 5000,
            span: 12,
            propsHttp: {
              res: 'data'
            },
            tip: '请上传身份证背面',
            action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km',
            rules: [
              {
                required: false,
                message: "请上传身份证正面",
                trigger: "change",
              },
            ],
          }, {
            disabled: !this.fileDisable,
            limit: 1,
            class: 'idCodingImg1',
            label: '人员近照',
            prop: 'recentPhoto',
            type: 'upload',
            props: {
              label: 'originalName',
              value: 'thumbnailLink'
            },
            dataType: 'object',
            listType: 'picture-card',
            accept: 'image/*',
            fileSize: 5000,
            span: 12,
            propsHttp: {
              res: 'data'
            },
            labelTipPlacement:'left',
            labelTip: '<p>1、五官无遮挡：如刘海、长发，勿遮挡眉毛、脸颊。不可出现曝光不均匀（阴阳脸）</p>' +
              '<p>2、正脸大头照：不能用精修的证件照</p>' +
              '<p>3、背景干净：不可出现其他人脸，过度曝光的背景环境</p>' +
              '<p>4、表情正常</p>' +
              '<p>5、照片比例为4:3，最佳分辨率为600*600以上</p>',
            action: '/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz-thumbnail/km',
            rules: [
              {
                required: false,
                message: "请上传人员近照",
                trigger: "change",
              },
            ],
          },
        ],
      }
    },
  },
  async mounted() {
    this.init()
   this.third_user_status =await  HD_SOURCE_TYPE('third_user_status').then(res=>res.data.data)
  },
  methods: {
    filterTree(arr, selectedKey) {
      arr.forEach(item => {
        if (item.children) {
          this.filterTree(item.children)
        }
        item.disabled = item.thirdFlg != this.form.thirdFlg;
      })
      return arr

    },
    // 下载二维码
    downloadQrcode() {
      this.$refs.qrcodeDialog.init(
        this.qrcodeSrc,
        this.form.userName,
        this.form.id
      );
    },
    validatePass(rule, value, callback) {
      let age = this.getAge(this.form)
      if (age < 18) {
        callback(new Error('年龄不能小于18'));
      } else if (age > 60) {
        callback(new Error('年龄不能超过60岁'));
      } else if (this.form.sex == 1 && age > 50) {
        callback(new Error('女生年龄不能超过50岁'));
      }
      callback();
    },
    getIsUsed(id, dict) {
      if (!dict) return ''
      let text = dict.filter(item => item.dictKey == id)[0] || {}
      return text.dictValue
    },
    getPost() {
      stpostList1(this.form.prjId).then(res => {
        this.postList = this.setDisabled(res.data.data)
      })
    },
    setDisabled(tree) {
      tree.forEach(item => {
        if (item.children) {
          this.setDisabled(item.children)
        }
        item.disabled = item.postType == 2 ? false : true;
      })
      return tree
    },
    handlePost(data) {
      if (!data.id) return
      let column = []
      // 获取资质证书列表
      cateList({
        postId: data.id,
      }).then((res) => {
        res.data.data.forEach((item, index) => {
          console.log('zhengshulist',item)
          let certificateCode = item.certificateCode.split('-')
          column.push({
            ...this.fileAppend,
            stPostId: data.id,
            label: item.certificateName,
            prop: `${certificateCode[0]}_${index}`,
          })
          column.push({
            stPostId: data.id,
            label: '证书编号',
            prop: `cerCode-${certificateCode[0]}_${index}`,
          })
          column.push({
            stPostId: data.id,
            label: '有效日期',
            prop: `limitDate-${certificateCode[0]}_${index}`,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            type: "date",
          })
        });
        this.selectOption.column = column
      });
      // 获取培训课程列表
      stpostList({
        postId: data.id,
      }).then((res) => {
        this.form.educateId1 = res.data.data[0]?.classId || '';
      });
    },
    handleClick(activeName, oldActiveName) {
      if (!this.form.id) {
        this.$message.error('请先保存数据，在切换')
        return false
      }
      if (activeName != 'ryzz') {
        if(activeName=='jkda') return
        this.showPage = false
        this.$nextTick(() => {
          this.showPage = true
          this.getTable()
        })
      } else {
        this.getFile()
      }
    },
    itemObject(item) {
      let age = this.getAge(item) || 0;
      if (age >= 60 || (item.sex == 1 && age > 50) || age < 18) {
        return {color: 'red'};
      } else {
        return {color: 'black'};
      }
    },
    callBack() {
      this.getDetail()
    },
    async getDetail() {
      this.dialogLoading = true
      await prjthirduserDetail({id: this.form.id}).then(res => {
        let data = res.data.data
        this.$set(this, 'form', {
          ...data,
          recentPhoto: data.recentPhoto && JSON.parse(data.recentPhoto),
          idCodingImg: data.idFiles && JSON.parse(data.idFiles),
          idCodingImg1: data.idFileBack && JSON.parse(data.idFileBack)
        })
      })
      this.qrcodeSrc = "data:image/png;base64," + this.form.baseCode?.codeUrl || ''
      this.handlePost(this.form.stPostId)
      this.handleClick(this.activeName)
      this.dialogLoading = false
    },
    async init() {
      let id = this.$route.query.id
      let deptId = this.$route.query.deptId
      let prjId = this.$route.query.prjId
      let type = this.$route.query.type
      this.$set(this, 'form', {})
      this.activeName = 'ryzz'
      this.showDialog = true
      this.treeReadOnlyFlag = false;
      this.pageType = this.$route.query.type
      if (type == 'edit') {
        this.title = '编辑相关方人员'
        this.$set(this, 'form', {
          id: id,
        })
        await this.getDetail()
      } else if (type == 'view') {
        this.title = '查看相关方人员'
        this.$set(this, 'form', {
          id: id,
        })
        await this.getDetail()
      } else {
        this.dialogLoading = false
        this.title = '新增相关方人员'
        this.$set(this, 'form', {
          prjId: prjId,
          deptId: deptId,
          isUsed: 2,
          thirdFlg: 1
        })
      }
      this.getPost()
      this.$forceUpdate()
    },
    // 身份证校验
    validateIdCard(rule, value, callback) {
      if (value == '') {
        callback(new Error('请填写身份证号码'));
      } else {
        let valid = PATTERN_ID_CARD.test(value)
        if (valid) {
          callback();
        } else {
          callback(new Error('请填写正确的身份证号码'));
        }
      }
    },
    //输入查询
    querySearch(queryString, cb) {
      let result = []
      this.isEdit = false
      if (queryString == '') {
        cb(result)
      } else {
        Promise.all([this.getListInfo(queryString, 'userName')]).then(() => {
          cb(this.searchData);
        })
        // 调用 callback 返回建议列表的数据
      }
    },
    queryCodeSearch(queryString, cb) {
      let result = []
      this.isEdit = false
      if (queryString == '') {
        cb(result)
      } else {
        Promise.all([this.getListInfo(queryString, 'idCoding')]).then(() => {
          cb(this.searchData);
        })
        // 调用 callback 返回建议列表的数据
      }
    },
    getListInfo(queryString, type) {
      this.query = {
        userName: queryString
      }
      return new Promise((r) => {
        getList(1, 20, this.query).then((res) => {
          let data = res.data.data.records
          let hasUseData = []
          if (data.length) {
            data.map((item) => {
              if (item.isUsed != 1) {
                hasUseData.push({
                  ...item,
                  value: type == 'idCoding' ? item.idCoding : item.userName
                })
              }
            })
            this.searchData = hasUseData
          } else {
            this.searchData = []
          }
          r()
        })
      })
    },
    getAge(data) {
      if (data.birthday) {
        if (!data.birthday) return ''
        return calculateAge(data.birthday)
      } else {
        if (!data.idCoding) return ''
        return getAgeFromIdCard(data.idCoding)
      }
    },

    handleSelect(item) {
      this.dialogLoading = true
      this.$set(this, 'form', {
        ...item,
        birthday: getBirthDate(item.idCoding),
        userId: item.userId,
        id: '',
        updateTime: '',
        updateUser: '',
        isUsed: '2',
        thirdFlg: '1',
        deptId: this.$route.query.deptId,
        prjId: this.$route.query.prjId,
        recentPhoto: item.recentPhoto && JSON.parse(item.recentPhoto),
        idCodingImg: item.idFiles && JSON.parse(item.idFiles),
        idCodingImg1: item.idFileBack && JSON.parse(item.idFileBack),
        idFiles: [],
        idFileBack: [],
      })
      this.$nextTick(() => {
        this.dialogLoading = false
      })
      console.log(this.form)
      this.$forceUpdate()
      this.isEdit = true
    },
    uploadExceed(limit, files, fileList, column) {
      console.log(limit, files, fileList, column)
      this.$message.error(`${column.label}上传数量限制${limit}张，如果需要重新上传，请先删除之前的图片`)
    },
    uploadDelete1(file, column) {
      this.form[column.prop].splice(file.uid, 1);
    },
    uploadAfter1(res, done, loading, column) {
      if (!this.form[column.prop]) {
        this.$set(this.form, column.prop, [])
      }
      if (column.prop == 'idCodingImg' && res.ocrSuccess == 1 && res.words_result['公民身份号码']) {
        this.form['idCoding'] = res.words_result['公民身份号码'].words
        this.form['userName'] = res.words_result['姓名'].words
        if (res.words_result['性别']) {
          this.form.sex = this.project_sex_type[res.words_result['性别'].words]
        }
        this.form.birthday= getBirthDate(this.form['idCoding']);
      } else if (column.prop == 'idCodingImg') {
        this.$message.error('身份证解析失败，请确认身份证照片无遮挡')
        loading()
      }
      this.form[column.prop].push({
        ...res,
        label: '身份证',
        value: res.link,
        url: res.link,
        thumbnailLink: res.link,
        uid: new Date().getTime(),
      })
      console.log(this.fileForm)
      this.$forceUpdate()
      loading()
    },
    handleTreeNodeSave() {
      this.$refs.addForm.submit();
    },
    treeNodeSave(data) {
      data.stPostName = data.$stPostId
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          this.saveData(data)
          if(data.id){
            this.headSave()
          }
        }
      })
    },

    headSave() {
      let listFile = []

      this.selectOption.column.forEach(((item, index) => {
         if(!item.prop.includes('-')){
           let certificateCodeProp = 'cerCode-'+item.prop
          let limitDateProp = 'limitDate-'+item.prop
            listFile.push(
              {
                thirdCompanyId: this.form.deptId,
                prjId: this.form.prjId,
                attr1: item.prop,
                attr2: item.label,
                userId: this.form.id,
                id: this.fileIds[item.prop] || '',
                images: JSON.stringify(this.fileForm[item.prop]),
                certificateOrder: index + 1,
                cerCode: this.fileForm[certificateCodeProp],
                limitDate: this.fileForm[limitDateProp],
                attr3: certificateCodeProp,
                attr4: limitDateProp,
                stPostId: this.form.stPostId
              }
            )
         }
        
      }))

      
      this.userOption.column.map((item => {
        
        if(!item.prop.includes('-')){
          let certificateCodeProp = 'cerCode-'+item.prop
          let limitDateProp = 'limitDate-'+item.prop
            listFile.push(
              { 
                thirdCompanyId: this.form.deptId,
                prjId: this.form.prjId,
                attr1: item.prop,
                userId: this.form.id,
                id: this.fileIds[item.prop] || '',
                images: JSON.stringify(this.fileForm[item.prop]),
                certificateOrder: listFile.length + 1,
                cerCode: this.fileForm[certificateCodeProp],
                limitDate: this.fileForm[limitDateProp],
                attr3: certificateCodeProp,
                attr4: limitDateProp,
                stPostId: this.form.stPostId
              }
            )
        }
        
      }))
    

    
      console.log('listFile',listFile)
      fileUserSubmit(listFile).then(res => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getFile()
        }
      })
    },
    getFile() {
      let fileForm = {},column=[],fileType=['business','permit']
      getFileUserList({
        userId: this.form.id,
        thirdCompanyId: this.form.deptId,
        prjId: this.form.prjId,
        stPostId: this.form.stPostId
      }).then(res => {
        res.data.data.forEach(item => {
          console.log('xiangqingitem',item)
          this.fileIds[item.attr1] = item.id
          fileForm[item.attr1] = item.images && JSON.parse(item.images)
          fileForm[item.attr3] = item.cerCode
          fileForm[item.attr4] = item.limitDate

          console.log('xiangqing',fileForm)
          if(!fileType.includes(item.attr1)){
            column.push(
              {
                ...this.fileAppend,
                prop: item.attr1,
                label: item.attr2,
              }
            )
            column.push(
              {
                prop: item.attr3?item.attr3:'cerCode-'+item.attr1,
                label: '证书编号',
                value: item.cerCode
              }
            )
            column.push(
              {
                prop: item.attr4?item.attr4:'limitDate'+item.attr1,
                label: '有效日期',
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd',
                type: "date",
                value: item.limitDate
              }
            )
          }
        })
        this.selectOption.column=column
        console.log('xiangqingColumn',column,this.selectOption.column)
        this.fileForm = fileForm
      })
    },
  }
}

</script>
<style lang="scss" >
.addThirduser {
  position: relative;
  .el-autocomplete {
    width: 100%
  }
  .titleSuffix{
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .main-box{
    padding-top: 46px;
    height: calc(100% - 46px);
    overflow: scroll;
  }
  .shuttleBackBox {
    background: #ffffff;
    //min-height: calc(100% + 350px);
  height: 100%;
  }

  .el-tabs--border-card > .el-tabs__header {
    border-bottom: 0;
  }

  .el-tabs__content {
    display: none;
  }

  .idCodingImg1 .el-upload-list__item {
    width: 226px;
    height: 132px;
  }

  .idCodingImg1 .el-upload--picture-card {
    height: 132px;
  }

  .margin-b-10 {
    margin-bottom: 10px;
  }

  .form-box {
    //height: calc(100% - 64px);
    //overflow: scroll;
    padding: 12px;
  }
  .hdStatus{
    margin-left: 10px;
  }
  .form-item {
    padding: 12px 0 0;
  }

  .avue-crud__search {
    .el-form-item__label {
      display: none;
    }

    .el-form-item__content {
      margin-left: 0 !important;
    }

    .reset-refresh {
      font-size: 12px;
    }

    .avue-form__menu {
      .el-button {
        margin: 0 !important;
      }
    }
  }
}

</style>
